import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { EnvService } from "./env.service";

@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor(
    private env: EnvService,
    private http: HttpClient
  ) { }

  public submitUserFormData(data): Observable<any> {
  	console.log(data);
    const url = `${this.env.apiBase}/taxid`;
    let params = new HttpParams()
      .append('userId', data.user_id);
    let headers = new HttpHeaders()
      .append('Authorization', data.api_key);
    return this.http.post<Object>(url, data, {
      params: params,
      headers: headers
    });
  }

  public submitUserFormDataGMBH(data): Observable<any> {
    const url = `${this.env.apiBase}/gmbh`;
    return this.http.post<Object>(url, data, {});
  }

}
