import {Component} from "@angular/core";
import {SharedService} from "./_services";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.less"]
})
export class AppComponent {
	lang: string;
	dataProtection = false;

	constructor(
		private translate: TranslateService,
		private _SharedService: SharedService,
		public router: Router,
		public route: ActivatedRoute
	) {
		this.route.queryParams.subscribe(res => {
			if (res["lang"]) {
				this.lang = res["lang"];
				this.router.navigate([], {
					relativeTo: this.route,
					queryParams: {}
				});
			} else {
				this.lang = localStorage.getItem("lang");
				if (!this.lang) {
					this.lang = "ru";
				}
			}
			translate.use(this.lang);
			localStorage.setItem("lang", this.lang);
			this._SharedService.changeLanguageEvent.emit(this.lang);
		});

		this.router.events.subscribe((val) => {
			if (val instanceof NavigationEnd) {
				//this.dataProtection = JSON.parse(localStorage.getItem("dataProtection"));
				this.dataProtection = true;
				if (this.dataProtection === null) {
					localStorage.setItem("dataProtection", JSON.stringify(true));
				}
			}
		});
	}

}
