import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {AlertService} from "../../_services";
import {FormControl, FormGroup, FormBuilder, Validators} from "@angular/forms";
import {RegistrationService} from "../../_services";

@Component({
	selector: "app-registration",
	templateUrl: "./registration.component.html",
	styleUrls: ["./registration.component.less"]
})
export class RegistrationComponent implements OnInit, AfterViewInit {
	@ViewChild("phone", {"static": false}) phone;
	
	registrationForm: FormGroup;
	
	savedData: any = {
		second_name: "",
		name: "",
		email: "",
		full_phone_number: "",
		phone_number: "",
		dial_code: "",
		service_node: 1
	};
	
	constructor(
		private _RegistrationService: RegistrationService,
		private router: Router,
		private route: ActivatedRoute,
		private _alertService: AlertService,
		private fb: FormBuilder,
		private elem: ElementRef,
		private renderer: Renderer2,
		private cd: ChangeDetectorRef
	) {
		let tempObj = JSON.parse(localStorage.getItem("formData"));
		if (tempObj) {
			this.savedData = {
				second_name: tempObj["section1"]["list"]["surname"]["value"],
				name: tempObj["section1"]["list"]["name"]["value"],
				email: tempObj["section1"]["list"]["email"]["value"],
				full_phone_number: tempObj["section1"]["list"]["phoneNumber"]["value"],
				phone_number: tempObj["section1"]["list"]["phoneNumber"]["value"].replace(tempObj["section1"]["list"]["dialCode"]["value"], ""),
				dial_code: tempObj["section1"]["list"]["dialCode"]["value"],
				service_node: tempObj["path"]
			};
		}
	}
	
	ngOnInit() {
		this.createForm();
	}
	
	ngAfterViewInit() {
		if (this.savedData.full_phone_number) {
			this.phone["phoneNumber"] = this.savedData.dial_code + "" + this.savedData.phone_number;
			this.phone.countries.forEach(obj => {
				if (obj["dialCode"] === this.savedData.dial_code.replace("+", "")) {
					this.phone["selectedCountry"] = {
						name: obj["name"],
						dialCode: obj["dialCode"],
						countryCode: obj["countryCode"]
					};
				}
			});
		}
		this.cd.detectChanges();
	}
	
	onchangeValidation(form): void {
		console.log(form);
		let dialCode = this.elem.nativeElement.querySelector(".phone_number").getAttribute("dialCode");
		let userInput = this.registrationForm.controls["phone_number"].value;
		if ((dialCode === "+false" && userInput.length || userInput === "+" + dialCode) && form.submitted) {
			this.registrationForm.controls["phone_number"].setErrors({"wrong_number": true});
		}
	}
	
	createForm(): void {
		const emailPattern = "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?";
		this.registrationForm = this.fb.group({
			name: [null, [Validators.required, Validators.maxLength(50)]],
			second_name: [null, [Validators.required, Validators.maxLength(50)]],
			phone_number: [null, [Validators.required]],
			email: [null, [Validators.required, Validators.pattern(emailPattern), Validators.maxLength(40)]]
		});
		this.registrationForm.get('name').setValue(this.savedData["name"]);
		this.registrationForm.get('second_name').setValue(this.savedData["second_name"]);
		this.registrationForm.get('phone_number').setValue(this.savedData["full_phone_number"]);
		this.registrationForm.get('email').setValue(this.savedData["email"]);
	}
	
	public registerMobileUser(form): void {
		this.onchangeValidation(form);
		let objToSent = {
			phone_code: "+" + this.elem.nativeElement.querySelector(".phone_number").getAttribute("dialCode"),
			phone_number: null,
			name: this.registrationForm.value.name,
			second_name: this.registrationForm.value.second_name,
			email: this.registrationForm.value.email,
			service: 3,
			service_node: this.savedData["service_node"],
			service_id: (this.savedData["service_node"] === 1) ? 3 : 4
		};
		objToSent["phone_number"] = this.registrationForm.value.phone_number.replace(objToSent.phone_code, "");
		console.log(objToSent);
		this._RegistrationService.registerMobileUser(objToSent)
		.subscribe(res => {
			localStorage.setItem("tempUserData", JSON.stringify({
				phone_token: res.phone_token,
				user_id: res.user_id
			}));
			this.router.navigate(["/sms-confirmation"]);
		}, error => {
			if (error.error.message === 'This phone number is already used') {
				this._alertService.error("Shared.The_phone_number_is_already_in_use");
			} else {
				this._alertService.error("Shared.Something_went_wrong");
			}
		});
	}
	
}
