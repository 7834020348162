import {Component, OnInit} from "@angular/core";
import {SharedService} from "../_services"

@Component({
	selector: "app-greetings",
	templateUrl: "./greetings.component.html",
	styleUrls: ["./greetings.component.less"]
})
export class GreetingsComponent implements OnInit {
	
	lang = localStorage.getItem('lang');
	
	constructor(
		private _SharedService: SharedService
	) {
		if (this.lang === 'uk') {
			this.lang = 'ua'
		}
		this._SharedService.changeLanguageEvent
		.subscribe((res) => {
			if (res === 'uk') {
				this.lang = 'ua'
			} else {
				this.lang = res;
			}
		});
	}
	
	ngOnInit() {
	}
	
}
