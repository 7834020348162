import {Component, ElementRef, OnInit, Renderer2, ViewChild} from "@angular/core";
import {SharedService, EnvService} from "../../../_services";
import {TranslateService} from "@ngx-translate/core";

@Component({
	selector: "app-header",
	templateUrl: "./header.component.html",
	styleUrls: ["./header.component.less"]
})
export class HeaderComponent implements OnInit {
	@ViewChild("mobilemenu", {"static": false}) mobilemenu: ElementRef;
	lang = localStorage.getItem("lang");
	linkLang = localStorage.getItem("lang");
	myProfileLink: string = "";
	
	constructor(
		private _SharedService: SharedService,
		private translate: TranslateService,
		private renderer: Renderer2,
		public _EnvService: EnvService
	) {
		if (this.linkLang === 'uk') {
			this.linkLang = 'ua'
		} else if (this.linkLang === 'de') {
			this.linkLang = '';
		}
		
		this.myProfileLink = this._EnvService["myProfileLink"] + 'login?lang=' + this.lang;
	}
	
	ngOnInit() {
		this._SharedService.changeLanguageEvent
		.subscribe((res) => {
			this.lang = res;
			this.translate.use(this.lang);
			localStorage.setItem("lang", this.lang);
			this.myProfileLink = this._EnvService["myProfileLink"] + 'login?lang=' + this.lang;
			if (res === 'uk') {
				this.linkLang = 'ua'
			} else {
				this.linkLang = res;
			}
		});
	}
	
	public useLanguage(lang): void {
		this._SharedService.changeLanguageEvent.emit(lang);
	}
	
	toggleMobileMenu(): void {
		let hasClass = this.mobilemenu.nativeElement.classList.contains("open");
		if (hasClass) {
			this.renderer.removeClass(this.mobilemenu.nativeElement, "open");
		} else {
			this.renderer.addClass(this.mobilemenu.nativeElement, "open");
		}
	}
	
}
