import {Directive, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import { Validator, NG_VALIDATORS, AbstractControl } from "@angular/forms";
import * as moment from "moment";

@Directive({
  selector: '[appDateValidator]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: DateValidatorDirective,
    multi: true
  }]
})
export class DateValidatorDirective implements Validator {
  
  validate(control: AbstractControl): {[key: string]: any} | null {
    const dateRegEx = new RegExp(/^\d{2}\.\d{2}\.\d{4}$/);
    if (!dateRegEx.test(control.value)) {
      return {'wrongDateFormat': true};
    } else {
      let moment1 = moment(control.value, 'DD.MM.YYYY');
      if (!moment1.isValid()) {
        return {'invalidDate': true};
      }
    }
    return null;
  }
  
  constructor() {}
}
