import {Component, ElementRef, OnInit, Renderer2, Input, Output, EventEmitter} from '@angular/core';
import { AlertService, RegistrationService } from "../../_services";
import { ActivatedRoute, Router } from "@angular/router";

import { FormControl, FormGroup, FormBuilder, Validators } from "@angular/forms";

@Component({
  selector: 'app-sms-confirmation',
  templateUrl: './sms-confirmation.component.html',
  styleUrls: ['./sms-confirmation.component.less']
})
export class SmsConfirmationComponent implements OnInit {
  
  acceptSmsForm: FormGroup;
  
  constructor(
    private _RegistrationService: RegistrationService,
    private router: Router,
    private route: ActivatedRoute,
    private _alertService: AlertService,
    private fb: FormBuilder
  ) {}
  
  ngOnInit() {
    this.createForm();
  }
  
  createForm(): void {
    this.acceptSmsForm = this.fb.group({
      sms_code: ['', [Validators.required, Validators.maxLength(10)]],
    });
  }
  
  public submitSms(): void {
    let objToSent = JSON.parse(localStorage.getItem('tempUserData'));
    if (objToSent === null) {
      objToSent = {
        sms_code: this.acceptSmsForm.value.sms_code
      };
    } else {
      objToSent['sms_code'] = this.acceptSmsForm.value.sms_code;
    }
    this._RegistrationService.acceptConfirmationSMS(objToSent)
      .subscribe(res => {
        localStorage.setItem('tempUserData', JSON.stringify({
          password_token: res['pwd_token'],
          user_id: res['user_id']
        }));
        this.router.navigate(['/password-setup']);
      }, error => {
        console.log(error);
        this._alertService.error('Shared.Something_went_wrong');
      });
  }

}
