import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NgForm} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {EnvService, FormService, SharedService} from "../_services";

@Component({
  selector: 'app-other-services-form',
  templateUrl: './other-services-form.component.html',
  styleUrls: ['./other-services-form.component.less']
})
export class OtherServicesFormComponent implements OnInit {
  @ViewChild("phone", {static: false}) phone;

  lang: any = {active: localStorage.getItem("lang")};

  formModel: any = {
    sendToEmail: false,
    list: {
      first_name: "",
      last_name: "",
      website: "",
      phone_code: "",
      phone_number: "",
      email: "",
      gmbh_services: "",
      avg_turnover: 0,
      avg_invoices: 0,
      cnt_employees: 0,
      our_services: [
        /*{
          id: 1,
          name: "Form_gmbh.OurService_1",
          active: false
        },
        {
          id: 2,
          name: "Form_gmbh.OurService_2",
          active: false
        },*/
        {
          id: 3,
          name: "Form_gmbh.OurService_3",
          active: false
        },
        {
          id: 4,
          name: "Form_gmbh.OurService_4",
          active: false
        },
        {
          id: 5,
          name: "Form_gmbh.OurService_5",
          active: false
        },
        {
          id: 6,
          name: "Form_gmbh.OurService_6",
          active: false
        },
        {
          id: 7,
          name: "Form_gmbh.OurService_7",
          active: false
        },
        {
          id: 8,
          name: "Form_gmbh.OurService_8",
          active: false
        },
        {
          id: 9,
          name: "Form_gmbh.OurService_9",
          active: false,
          other_services: ""
        }
      ]
    }
  };
  myProfileLink: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private elem: ElementRef,
    private _SharedService: SharedService,
    private _FormService: FormService,
    public _EnvService: EnvService
  ) {
    this.myProfileLink = this._EnvService["myProfileLink"] + "login?lang=" + this.lang.active;
  }

  ngOnInit() {
    this._SharedService.changeLanguageEvent
      .subscribe((res) => {
        this.lang.active = res;
        this.myProfileLink = this._EnvService["myProfileLink"] + "login?lang=" + this.lang.active;
      });
  }

  public isObjectEmpty(obj): boolean {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }
  onchangeValidation(form: NgForm): void {
    form.controls['phone_number'].setErrors(null);
    if (this.phone.selectedCountry === null && this.elem.nativeElement.querySelector('.phone_number').getAttribute('dialCode') === 'false') {
      form.controls['phone_number'].setErrors({'wrong_number': true});
    }
    if (!form.controls['phone_number'].value.length) {
      form.controls['phone_number'].setErrors({'required': true});
    }
  }

  public submitForm(data): void {
    data["list"]["phone_code"] = "+" + this.elem.nativeElement.querySelector(".phone_number").getAttribute("dialCode");
    let ourServices = [];
    data["list"]["our_services"].forEach((obj, i) => {
      if (obj["active"]) {
        if (i === data["list"]["our_services"].length - 1) {
          ourServices.push(obj["other_services"]);
        } else {
          ourServices.push(obj["id"].toString());
        }
      }
    });

    //SERVICE_GMBH = 2;
    //SERVICE_PRIVATE_OTHER = 5

    let objToSend = {
      service: 5,
      first_name: data["list"]["first_name"],
      last_name: data["list"]["last_name"],
      website: data["list"]["website"],
      phone_code: data["list"]["phone_code"],
      phone_number: data["list"]["phone_number"].replace(data["list"]["phone_code"], ""),
      email: data["list"]["email"],
      gmbh_services: data["list"]["gmbh_services"],
      avg_turnover: data["list"]["avg_turnover"],
      avg_invoices: data["list"]["avg_invoices"],
      cnt_employees: data["list"]["cnt_employees"],
      our_services: ourServices
    };

    console.log(objToSend);
    localStorage.setItem('formData',JSON.stringify(objToSend))
    //this.router.navigate(["/registration"]);
    this._FormService.submitUserFormDataGMBH(objToSend)
    .subscribe(res => {
      console.log(res);
      this.router.navigate(["/greetings"]);
    }, err => {
      console.log(err);
    });
  }

  public printForm(data): void {
    window.print();
  }

}
