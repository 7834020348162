import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {ActivatedRoute, Router, NavigationEnd} from "@angular/router";
import {FormGroupDirective, NgForm, FormControl} from "@angular/forms";
import {SharedService, EnvService} from "../_services";
import {TranslateService} from "@ngx-translate/core";

@Component({
	selector: "app-form",
	templateUrl: "./form.component.html",
	styleUrls: ["./form.component.less"]
})
export class FormComponent implements OnInit {
	@ViewChild("section1Form", {"static": false}) section1Form: FormGroupDirective;
	@ViewChild("section2Form", {"static": false}) section2Form: FormGroupDirective;
	@ViewChild("section3Form", {"static": false}) section3Form: FormGroupDirective;
	@ViewChild("section4Form", {"static": false}) section4Form: FormGroupDirective;
	@ViewChild("section5Form", {"static": false}) section5Form: FormGroupDirective;
	@ViewChild("section6Form", {"static": false}) section6Form: FormGroupDirective;
	@ViewChild("section7Form", {"static": false}) section7Form: FormGroupDirective;
	@ViewChild("section8Form", {"static": false}) section8Form: FormGroupDirective;
	@ViewChild("phone", {"static": false}) phone;

	lang: any = {active: localStorage.getItem("lang")};
	path: any = null;

	formModel: any = {
		sendToEmail: false,
		path: null,
		section1: {
			id: 1,
			title: "Section1",
			active: true,
			valid: false,
			conditionalSectionsToggle: {
				movedToCurrentAddress: false
			},
			list: {
				name: {
					id: 1,
					value: ""
				},
				surname: {
					id: 2,
					value: ""
				},
				maidenName: {
					id: 3,
					value: ""
				},
				profession: {
					id: 4,
					value: ""
				},
				dateOfBirth: {
					id: 5,
					value: ""
				},
				streetOfResidence: {
					id: 6,
					value: ""
				},
				houseNumber: {
					id: 7,
					value: ""
				},
				additionToTheAddress: {
					id: 8,
					value: ""
				},
				zipCode: {
					id: 9,
					value: ""
				},
				city: {
					id: 10,
					value: ""
				},
				tin: {
					id: 11,
					value: ""
				},
				religiousAffiliationToAParticularChurch: {
					id: 12,
					value: ""
				},
				dialCode: {
					id: 13,
					value: ""
				},
				phoneNumber: {
					id: 14,
					value: ""
				},
				email: {
					id: 15,
					value: ""
				},
				professionDetails: {
					id: 16,
					value: ""
				},
				taxAdvice: {
					id: 64,
					value: true
				},
				taxAdviceFirm: {
					id: 65,
					value: "Proton Steuerberatungsgesellschaft mbH"
				},
				taxAdviceSurname: {
					id: 66,
					value: ""
				},
				taxAdviceName: {
					id: 67,
					value: ""
				},
				taxAdviceStreet: {
					id: 68,
					value: "Storkower Str."
				},
				taxAdviceHouseNumber: {
					id: 69,
					value: "113"
				},
				taxAdviceZipCode: {
					id: 70,
					value: "10407"
				},
				taxAdviceCity: {
					id: 71,
					value: "Berlin"
				},
				taxAdviceIntCode: {
					id: 72,
					value: "0049"
				},
				taxAdviceNationalCode: {
					id: 73,
					value: "30"
				},
				taxAdvicePhoneNumber: {
					id: 74,
					value: "20097170"
				},
				taxAdviceEmail: {
					id: 75,
					value: "info@proton.tax"
				},
				dateOfTheMove: {
					id: 17,
					value: ""
				},
				previousTax: {
					id: 18,
					value: ""
				},
				previousTaxNumber: {
					id: 19,
					value: ""
				},
				previousCity: {
					id: 78,
					value: ""
				},
				previousStreet: {
					id: 79,
					value: ""
				},
				previousStreetNumber: {
					id: 80,
					value: ""
				},
				previousZipCode: {
					id: 81,
					value: ""
				}
			}
		},
		section2: {
			id: 2,
			title: "Section2",
			active: false,
			valid: false,
			conditionalSectionsToggle: {
				enterpriseAddress: false,
				differentBranches: false,
				previousFirm: false,
				previousBusiness: false
			},
			list: {
				enterpriseName: {
					id: 77,
					value: ""
				},
				enterpriseAddress: {
					id: 20,
					value: ""
				},
				enterpriseHouseNumber: {
					id: 21,
					value: ""
				},
				enterpriseAddressAdditions: {
					id: 22,
					value: ""
				},
				enterpriseZipcode: {
					id: 23,
					value: ""
				},
				enterpriseCity: {
					id: 24,
					value: ""
				},
				startOfPlannedActivities: {
					id: 25,
					value: ""
				},
				differentBranches: {
					id: 26,
					value: "",
					itemsList: [
						{
							street: "",
							houseNumber: "",
							addressAdditions: "",
							zipCode: "",
							city: ""
						}
					]
				},
				foundationFormOfTheEnterprise: {
					id: 27,
					value: "",
					itemsList: [{
						id: 1,
						name: "Form.Fields2.startNewActivity"
					}, {
						id: 2,
						name: "Form.Fields2.transferOfPreviouslyRegisteredActivities"
					}, {
						id: 3,
						name: "Form.Fields2.purchaseLeaseInheritanceOfBusiness"
					}, {
						id: 4,
						name: "Form.Fields2.conversionFromAnotherLegalForm"
					}]
				},
				foundationDateOfTheEnterprise: {
					id: 29,
					value: ""
				},
				previousFirmName: {
					id: 30,
					value: ""
				},
				previousFirmOwnerName: {
					id: 31,
					value: ""
				},
				previousFirmOwnerSurname: {
					id: 32,
					value: ""
				},
				previousFirmAddress: {
					id: 33,
					value: ""
				},
				previousBusinessDetails: {
					id: 34,
					value: ""
				},
				previousBusinessAddress: {
					id: 35,
					value: ""
				},
				previousBusinessStartDate: {
					id: 36,
					value: ""
				},
				previousBusinessEndDate: {
					id: 37,
					value: ""
				},
				previousBusinessTax: {
					id: 38,
					value: ""
				},
				previousBusinessTaxNumber: {
					id: 39,
					value: ""
				}
			}
		},
		section3: {
			id: 3,
			title: "Section3",
			active: false,
			valid: false,
			conditionalSectionsToggle: {},
			list: {
				plannedRevenueFromYourBusiness: {
					id: 40,
					value: 0
				}
			}
		},
		section4: {
			id: 4,
			title: "Section4",
			active: false,
			valid: false,
			conditionalSectionsToggle: {},
			list: {
				methodOfDeterminingProfit: {
					id: 41,
					value: "Einnahmenüberschussrechnung"
				},
				isYourCommercialYearDifferentFromCalendar: {
					id: 42,
					value: "no"
				}
			}
		},
		section5: {
			id: 5,
			title: "Section5",
			active: false,
			valid: false,
			conditionalSectionsToggle: {},
			list: {
				exemptionFromConstructionServiceTax: {
					id: 43,
					value: (this.path !== 1)
				}
			}
		},
		section6: {
			id: 6,
			title: "Section6",
			active: false,
			valid: false,
			conditionalSectionsToggle: {
				inCaseYouWillHireEmployees: false
			},
			list: {
				totalNumberOfEmployees: {
					id: 44,
					value: ""
				},
				familyMembers: {
					id: 45,
					value: ""
				},
				busyAtMinimumRate: {
					id: 46,
					value: ""
				},
				startingPayroll: {
					id: 47,
					value: ""
				}
			}
		},
		section7: {
			id: 7,
			title: "Section7",
			active: false,
			valid: false,
			conditionalSectionsToggle: {},
			list: {
				turnoverInTheYearOfOperation: {
					id: 48,
					value: ""
				},
				turnoverInTheNextYearOfOperation: {
					id: 49,
					value: ""
				},
				purchasedAsWholeOrASeparateUnit: {
					id: 50,
					value: ""
				},
				smallEntrepreneur: {
					id: 51,
					value: null,
					itemsList: [{
						id: 1,
						name: "Form.Fields7.sellerWantsDiscount"
					}, {
						id: 2,
						name: "Form.Fields7.sellerDoesntWantDiscount"
					}, {
						id: 3,
						name: "Form.Fields7.sellerNotRelated"
					}]
				},
				mySalesWillBeFreeOfVAT: {
					id: 52,
					value: "No"
				},
				mySalesWillHaveReducedRate: {
					id: 53,
					value: "No"
				},
				willSellGoodsOrServicesWithAverageRates: {
					id: 54,
					value: "No"
				},
				taxMyEarningsByCashMethod: {
					id: 55,
					value: true
				},
				iNeedEuropeanTaxNumberOfVAT: {
					id: 56,
					value: false
				},
				confirmTransitionVATToRecipient: {
					id: 57,
					value: false
				}
			}
		},
		section8: {
			id: 8,
			title: "Section8",
			active: false,
			valid: false,
			conditionalSectionsToggle: {
				ifYouAreAMemberOfThePartnership: false
			},
			list: {
				partnershipName: {
					id: 58,
					value: ""
				},
				partnershipStreet: {
					id: 59,
					value: ""
				},
				partnershipHouseNumber: {
					id: 60,
					value: ""
				},
				partnershipAddressAdditions: {
					id: 61,
					value: ""
				},
				partnershipZipcode: {
					id: 62,
					value: ""
				},
				partnershipCity: {
					id: 63,
					value: ""
				}
			}
		}
	};

	myProfileLink: string = '';

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private elem: ElementRef,
		private _SharedService: SharedService,
		private translate: TranslateService,
		public _EnvService: EnvService
	) {
		this.myProfileLink = this._EnvService["myProfileLink"];
		this.router.events.subscribe((val) => {
			if (val instanceof NavigationEnd) {
				if (this.isObjectEmpty(this.route.snapshot.queryParams)) {
					this.formModel["path"] = null;
					this.path = null;
				} else {
					this.path = parseInt(this.route.snapshot.queryParams["path"], 10);
					this.formModel["path"] = parseInt(this.route.snapshot.queryParams["path"], 10);
				}
			}
		});
	}

	ngOnInit() {
		this._SharedService.changeLanguageEvent
		.subscribe((res) => {
			this.lang.active = res;
		});
	}

	goToOtherServices(): void {
	  this.router.navigate(['/other-services']);
  }

	setPath(path): void {
		this.router.navigate(["/"], {queryParams: {path: path}});
		this.formModel = {
			sendToEmail: false,
			path: path,
			section1: {
				id: 1,
				title: "Section1",
				active: true,
				valid: false,
				conditionalSectionsToggle: {
					movedToCurrentAddress: false
				},
				list: {
					name: {
						id: 1,
						value: ""
					},
					surname: {
						id: 2,
						value: ""
					},
					maidenName: {
						id: 3,
						value: ""
					},
					profession: {
						id: 4,
						value: ""
					},
					dateOfBirth: {
						id: 5,
						value: ""
					},
					streetOfResidence: {
						id: 6,
						value: ""
					},
					houseNumber: {
						id: 7,
						value: ""
					},
					additionToTheAddress: {
						id: 8,
						value: ""
					},
					zipCode: {
						id: 9,
						value: ""
					},
					city: {
						id: 10,
						value: ""
					},
					tin: {
						id: 11,
						value: ""
					},
					religiousAffiliationToAParticularChurch: {
						id: 12,
						value: ""
					},
					dialCode: {
						id: 13,
						value: ""
					},
					phoneNumber: {
						id: 14,
						value: ""
					},
					email: {
						id: 15,
						value: ""
					},
					professionDetails: {
						id: 16,
						value: ""
					},
					taxAdvice: {
						id: 64,
						value: true
					},
					taxAdviceFirm: {
						id: 65,
						value: "Proton Steuerberatungsgesellschaft mbH"
					},
					taxAdviceSurname: {
						id: 66,
						value: ""
					},
					taxAdviceName: {
						id: 67,
						value: ""
					},
					taxAdviceStreet: {
						id: 68,
						value: "Storkower Str."
					},
					taxAdviceHouseNumber: {
						id: 69,
						value: "113"
					},
					taxAdviceZipCode: {
						id: 70,
						value: "10407"
					},
					taxAdviceCity: {
						id: 71,
						value: "Berlin"
					},
					taxAdviceIntCode: {
						id: 72,
						value: "0049"
					},
					taxAdviceNationalCode: {
						id: 73,
						value: "30"
					},
					taxAdvicePhoneNumber: {
						id: 74,
						value: "20097170"
					},
					taxAdviceEmail: {
						id: 75,
						value: "info@proton.tax"
					},
					dateOfTheMove: {
						id: 17,
						value: ""
					},
					previousTax: {
						id: 18,
						value: ""
					},
					previousTaxNumber: {
						id: 19,
						value: ""
					},
					previousCity: {
						id: 78,
						value: ""
					},
					previousStreet: {
						id: 79,
						value: ""
					},
					previousStreetNumber: {
						id: 80,
						value: ""
					},
					previousZipCode: {
						id: 81,
						value: ""
					}
				}
			},
			section2: {
				id: 2,
				title: "Section2",
				active: false,
				valid: false,
				conditionalSectionsToggle: {
					enterpriseAddress: false,
					differentBranches: false,
					previousFirm: false,
					previousBusiness: false
				},
				list: {
					enterpriseName: {
						id: 77,
						value: ""
					},
					enterpriseAddress: {
						id: 20,
						value: ""
					},
					enterpriseHouseNumber: {
						id: 21,
						value: ""
					},
					enterpriseAddressAdditions: {
						id: 22,
						value: ""
					},
					enterpriseZipcode: {
						id: 23,
						value: ""
					},
					enterpriseCity: {
						id: 24,
						value: ""
					},
					startOfPlannedActivities: {
						id: 25,
						value: ""
					},
					differentBranches: {
						id: 26,
						value: "",
						itemsList: [
							{
								street: "",
								houseNumber: "",
								addressAdditions: "",
								zipCode: "",
								city: ""
							}
						]
					},
					foundationFormOfTheEnterprise: {
						id: 27,
						value: "",
						itemsList: [{
							id: 1,
							name: "Form.Fields2.startNewActivity"
						}, {
							id: 2,
							name: "Form.Fields2.transferOfPreviouslyRegisteredActivities"
						}, {
							id: 3,
							name: "Form.Fields2.purchaseLeaseInheritanceOfBusiness"
						}, {
							id: 4,
							name: "Form.Fields2.conversionFromAnotherLegalForm"
						}]
					},
					foundationDateOfTheEnterprise: {
						id: 29,
						value: ""
					},
					previousFirmName: {
						id: 30,
						value: ""
					},
					previousFirmOwnerName: {
						id: 31,
						value: ""
					},
					previousFirmOwnerSurname: {
						id: 32,
						value: ""
					},
					previousFirmAddress: {
						id: 33,
						value: ""
					},
					previousBusinessDetails: {
						id: 34,
						value: ""
					},
					previousBusinessAddress: {
						id: 35,
						value: ""
					},
					previousBusinessStartDate: {
						id: 36,
						value: ""
					},
					previousBusinessEndDate: {
						id: 37,
						value: ""
					},
					previousBusinessTax: {
						id: 38,
						value: ""
					},
					previousBusinessTaxNumber: {
						id: 39,
						value: ""
					}
				}
			},
			section3: {
				id: 3,
				title: "Section3",
				active: false,
				valid: false,
				conditionalSectionsToggle: {},
				list: {
					plannedRevenueFromYourBusiness: {
						id: 40,
						value: 0
					}
				}
			},
			section4: {
				id: 4,
				title: "Section4",
				active: false,
				valid: false,
				conditionalSectionsToggle: {},
				list: {
					methodOfDeterminingProfit: {
						id: 41,
						value: "Einnahmenüberschussrechnung"
					},
					isYourCommercialYearDifferentFromCalendar: {
						id: 42,
						value: "no"
					}
				}
			},
			section5: {
				id: 5,
				title: "Section5",
				active: false,
				valid: false,
				conditionalSectionsToggle: {},
				list: {
					exemptionFromConstructionServiceTax: {
						id: 43,
						value: (path !== 1)
					}
				}
			},
			section6: {
				id: 6,
				title: "Section6",
				active: false,
				valid: false,
				conditionalSectionsToggle: {
					inCaseYouWillHireEmployees: false
				},
				list: {
					totalNumberOfEmployees: {
						id: 44,
						value: ""
					},
					familyMembers: {
						id: 45,
						value: ""
					},
					busyAtMinimumRate: {
						id: 46,
						value: ""
					},
					startingPayroll: {
						id: 47,
						value: ""
					}
				}
			},
			section7: {
				id: 7,
				title: "Section7",
				active: false,
				valid: false,
				conditionalSectionsToggle: {},
				list: {
					turnoverInTheYearOfOperation: {
						id: 48,
						value: ""
					},
					turnoverInTheNextYearOfOperation: {
						id: 49,
						value: ""
					},
					purchasedAsWholeOrASeparateUnit: {
						id: 50,
						value: ""
					},
					smallEntrepreneur: {
						id: 51,
						value: null,
						itemsList: [{
							id: 1,
							name: "Form.Fields7.sellerWantsDiscount"
						}, {
							id: 2,
							name: "Form.Fields7.sellerDoesntWantDiscount"
						}, {
							id: 3,
							name: "Form.Fields7.sellerNotRelated"
						}]
					},
					mySalesWillBeFreeOfVAT: {
						id: 52,
						value: "No"
					},
					mySalesWillHaveReducedRate: {
						id: 53,
						value: "No"
					},
					willSellGoodsOrServicesWithAverageRates: {
						id: 54,
						value: "No"
					},
					taxMyEarningsByCashMethod: {
						id: 55,
						value: true
					},
					iNeedEuropeanTaxNumberOfVAT: {
						id: 56,
						value: false
					},
					confirmTransitionVATToRecipient: {
						id: 57,
						value: true
					}
				}
			},
			section8: {
				id: 8,
				title: "Section8",
				active: false,
				valid: false,
				conditionalSectionsToggle: {
					ifYouAreAMemberOfThePartnership: false
				},
				list: {
					partnershipName: {
						id: 58,
						value: ""
					},
					partnershipStreet: {
						id: 59,
						value: ""
					},
					partnershipHouseNumber: {
						id: 60,
						value: ""
					},
					partnershipAddressAdditions: {
						id: 61,
						value: ""
					},
					partnershipZipcode: {
						id: 62,
						value: ""
					},
					partnershipCity: {
						id: 63,
						value: ""
					}
				}
			}
		};
		this.path = path;
		// this.formModel['path'] = path;
	}

	toggleQ(section, toggle_data): void {
		switch (toggle_data) {
			case "taxAdvice":
				console.log(this.formModel.section1.list.taxAdvice.value);
				if (this.formModel.section1.list.taxAdvice.value) {
					this.formModel.section1.list.taxAdviceFirm.value = "Proton Steuerberatungsgesellschaft mbH";
					this.formModel.section1.list.taxAdviceStreet.value = "Storkower Str.";
					this.formModel.section1.list.taxAdviceHouseNumber.value = "113";
					this.formModel.section1.list.taxAdviceZipCode.value = "10407";
					this.formModel.section1.list.taxAdviceCity.value = "Berlin";
					this.formModel.section1.list.taxAdviceIntCode.value = "0049";
					this.formModel.section1.list.taxAdviceNationalCode.value = "30";
					this.formModel.section1.list.taxAdvicePhoneNumber.value = "20097170";
					this.formModel.section1.list.taxAdviceEmail.value = "info@proton.tax";
				}
				break;
			case "movedToCurrentAddress":
				this.formModel[section]["list"]["dateOfTheMove"].value = "";
				this.formModel[section]["list"]["previousTax"].value = "";
				this.formModel[section]["list"]["previousTaxNumber"].value = "";
				this.formModel[section]["list"]["previousCity"].value = "";
				this.formModel[section]["list"]["previousStreet"].value = "";
				this.formModel[section]["list"]["previousStreetNumber"].value = "";
				this.formModel[section]["list"]["previousZipCode"].value = "";
				break;
			case "enterpriseAddress":
				this.formModel[section]["list"]["enterpriseAddress"].value = this.formModel["section1"]["list"]["streetOfResidence"]["value"];
				this.formModel[section]["list"]["enterpriseHouseNumber"].value = this.formModel["section1"]["list"]["houseNumber"]["value"];
				this.formModel[section]["list"]["enterpriseAddressAdditions"].value = this.formModel["section1"]["list"]["additionToTheAddress"]["value"];
				this.formModel[section]["list"]["enterpriseZipcode"].value = this.formModel["section1"]["list"]["zipCode"]["value"];
				this.formModel[section]["list"]["enterpriseCity"].value = this.formModel["section1"]["list"]["city"]["value"];
				break;
			case "differentBranches":
				this.formModel[section]["list"]["differentBranches"].itemsList.splice(1);
				this.formModel[section]["list"]["differentBranches"].itemsList[0] = {
					street: "",
					houseNumber: "",
					addressAdditions: "",
					zipCode: "",
					city: ""
				};
				break;
			case "previousFirm":
				this.formModel[section]["list"]["previousFirmName"].value = "";
				this.formModel[section]["list"]["previousFirmOwnerName"].value = "";
				this.formModel[section]["list"]["previousFirmOwnerSurname"].value = "";
				this.formModel[section]["list"]["previousFirmAddress"].value = "";
				break;
			case "previousBusiness":
				this.formModel[section]["list"]["previousBusinessDetails"].value = "";
				this.formModel[section]["list"]["previousBusinessAddress"].value = "";
				this.formModel[section]["list"]["previousBusinessStartDate"].value = "";
				this.formModel[section]["list"]["previousBusinessEndDate"].value = "";
				this.formModel[section]["list"]["previousBusinessTax"].value = "";
				this.formModel[section]["list"]["previousBusinessTaxNumber"].value = "";
				break;
			case "inCaseYouWillHireEmployees":
				this.formModel[section]["list"]["totalNumberOfEmployees"].value = "";
				this.formModel[section]["list"]["familyMembers"].value = "";
				this.formModel[section]["list"]["busyAtMinimumRate"].value = "";
				this.formModel[section]["list"]["startingPayroll"].value = "";
				break;
			case "ifYouAreAMemberOfThePartnership":
				this.formModel[section]["list"]["partnershipName"].value = "";
				this.formModel[section]["list"]["partnershipStreet"].value = "";
				this.formModel[section]["list"]["partnershipHouseNumber"].value = "";
				this.formModel[section]["list"]["partnershipAddressAdditions"].value = "";
				this.formModel[section]["list"]["partnershipZipcode"].value = "";
				this.formModel[section]["list"]["partnershipCity"].value = "";
				break;
		}
	}

	public taxAdviceChangeEvent(e): void {
		if (this.formModel.section1.list.taxAdvice.value) {
			this.formModel.section1.list.taxAdvice.value = false;
		}
	}

	public isObjectEmpty(obj): boolean {
		for (let key in obj) {
			if (obj.hasOwnProperty(key)) {
				return false;
			}
		}
		return true;
	}

	/*onchangeValidation(form: NgForm): void {
		let dialCode = "+" + this.elem.nativeElement.querySelector(".phone_number").getAttribute("dialCode");
		let userInput = form.controls["phoneNumber"].value;
		this.formModel["section1"]["list"]["dialCode"].value = dialCode;
		if ((dialCode === "+false" && userInput.length || userInput === "+" + dialCode) && form.submitted) {
			form.controls["phoneNumber"].setErrors({"wrong_number": true});
		}
	}*/
	onchangeValidation(form: NgForm): void {
		form.controls['phoneNumber'].setErrors(null);
		if (this.phone.selectedCountry === null && this.elem.nativeElement.querySelector('.phone_number').getAttribute('dialCode') === 'false') {
			form.controls['phoneNumber'].setErrors({'wrong_number': true});
		}
		if (!form.controls['phoneNumber'].value.length) {
			form.controls['phoneNumber'].setErrors({'required': true});
		}
	}

	public scrollIntoView($elem): void {
		$elem.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
	}

	public addBranchAddresses(): void {
		let obj = Object.assign({
			street: "",
			houseNumber: "",
			addressAdditions: "",
			zipCode: "",
			city: ""
		});
		this.formModel.section2.list.differentBranches.itemsList.push(obj);
	}

	public deleteBranchAddresses(): void {
		if (this.formModel.section2.list.differentBranches.itemsList.length > 1) {
			this.formModel.section2.list.differentBranches.itemsList.pop();
		}
	}

	public printForm(data): void {
		window.print();
	}

	public submitForm(form, id, $elem): void {
		Object.keys(this.formModel).forEach((obj, index) => {
			if (index !== 0 && index !== 1) {
				(this.formModel[obj].id === (id + 1)) ? this.formModel[obj]["active"] = true : this.formModel[obj]["active"] = false;
				if (id === 2) {
					if (this.path === 1) {
						this.formModel["section2"]["active"] = false;
						this.formModel["section3"]["active"] = false;
						this.formModel["section4"]["active"] = false;
						this.formModel["section5"]["active"] = false;
						this.formModel["section6"]["active"] = true;
					} else {
						this.formModel["section2"]["active"] = false;
						this.formModel["section3"]["active"] = false;
						this.formModel["section4"]["active"] = false;
						this.formModel["section5"]["active"] = true;
					}
				}

				if (this.formModel[obj].id === (id)) {
					(form.status === "VALID") ? this.formModel[obj]["valid"] = true : this.formModel[obj]["valid"] = false;
				}
			}
		});
		this.scrollIntoView($elem);
		localStorage.setItem("formData", JSON.stringify(this.formModel));
	}

	public globalSubmit(data): void {
		let dataToSend = {
			taxid_id: 0,
			user_id: 0,
			fields: []
		};

		Object.keys(data).forEach((obj, index) => {
			if (index !== 0 && index !== 1) {
				data[obj]["valid"] = false;
			}
		});

		this.section1Form.onSubmit(undefined);
		this.section2Form.onSubmit(undefined);
		this.section3Form.onSubmit(undefined);
		this.section4Form.onSubmit(undefined);
		this.section5Form.onSubmit(undefined);
		this.section6Form.onSubmit(undefined);
		this.section7Form.onSubmit(undefined);
		this.section8Form.onSubmit(undefined);
		let validForms = [];
		Object.keys(data).forEach((obj, index) => {
			if (index !== 0 && index !== 1) {
				this.formModel[obj]["active"] = false;
				if (data[obj]["valid"]) {
					validForms.push(data[obj]["id"]);
				}
			}
		});

		console.log(validForms);
		if (validForms.length === 8) {
			console.log(this.formModel["section5"]["list"]["exemptionFromConstructionServiceTax"]);
			this.formModel["section2"]["list"]["foundationDateOfTheEnterprise"].value = this.formModel["section2"]["list"]["startOfPlannedActivities"].value;

			if (this.formModel["path"] === 2) {
				this.formModel["section7"]["list"]["confirmTransitionVATToRecipient"]["value"] = true;
			}

			if (!this.formModel["section2"]["conditionalSectionsToggle"]["enterpriseAddress"]) {
				this.formModel["section2"]["list"]["enterpriseAddress"].value = this.formModel["section1"]["list"]["streetOfResidence"]["value"];
				this.formModel["section2"]["list"]["enterpriseHouseNumber"].value = this.formModel["section1"]["list"]["houseNumber"]["value"];
				this.formModel["section2"]["list"]["enterpriseAddressAdditions"].value = this.formModel["section1"]["list"]["additionToTheAddress"]["value"];
				this.formModel["section2"]["list"]["enterpriseZipcode"].value = this.formModel["section1"]["list"]["zipCode"]["value"];
				this.formModel["section2"]["list"]["enterpriseCity"].value = this.formModel["section1"]["list"]["city"]["value"];
			}

			data["section1"]["list"]["dialCode"].value = "+" + this.elem.nativeElement.querySelector(".phone_number").getAttribute("dialCode");
			// data["section1"]["list"]["phoneNumber"].value =  data["section1"]["list"]["phoneNumber"].value.replace(this.formModel["section1"]["list"]["dialCode"].value, "");
			this.phone.countries.forEach(obj => {
				if (obj["dialCode"] === data["section1"]["list"]["dialCode"].value.replace("+", "")) {
					this.phone["selectedCountry"] = {
						name: obj["name"],
						dialCode: obj["dialCode"],
						countryCode: obj["countryCode"]
					};
				}
			});

			Object.keys(data).forEach((obj, index) => {
				if (index !== 0 && index !== 1) {
					Object.keys(data[obj]["list"]).forEach((deep_obj, deep_index) => {
						if (data[obj]["list"][deep_obj]["id"] === 26) {

							if (data[obj]["conditionalSectionsToggle"]["differentBranches"]) {
								data[obj]["list"][deep_obj]["value"] = "";
								data[obj]["list"][deep_obj]["itemsList"].forEach(res => {
									let resArray = [];
									resArray.push(res.street, res.houseNumber, res.addressAdditions, res.zipCode, res.city);
									let arrayToString = resArray.join(".");
									data[obj]["list"][deep_obj]["value"] = data[obj]["list"][deep_obj]["value"] + arrayToString + "///";
								});
							} else {
								data[obj]["list"][deep_obj]["value"] = "";
							}

						}
						dataToSend.fields.push({
							[data[obj]["title"] + "_" + [deep_obj]]: data[obj]["list"][deep_obj]["value"]
						});
					});
				}
			});

			console.log(dataToSend);
			console.log(this.formModel);
			localStorage.setItem("formData", JSON.stringify(this.formModel));
			localStorage.setItem("formDataToSend", JSON.stringify(dataToSend));
			this.router.navigate(["/registration"]);
		}
	}

}
