import {Component, ElementRef, OnInit, Renderer2, Input} from "@angular/core";
import {AlertService, RegistrationService, SharedService} from "../../_services/index";
import {ActivatedRoute, Router} from "@angular/router";

import {FormControl, FormGroup, FormBuilder, Validators} from "@angular/forms";
import {FormService} from "../../_services/form.service";

@Component({
	selector: "app-pwd-setup",
	templateUrl: "./pwd-setup.component.html",
	styleUrls: ["./pwd-setup.component.less"]
})
export class PwdSetupComponent implements OnInit {
	setupPwdForm: FormGroup;
	
	constructor(
		private _RegistrationService: RegistrationService,
		private router: Router,
		private route: ActivatedRoute,
		private _alertService: AlertService,
		private fb: FormBuilder,
		private _FormService: FormService,
		private _SharedService: SharedService
	) {
	}
	
	ngOnInit() {
		this.createForm();
	}
	
	createForm(): void {
		this.setupPwdForm = this.fb.group({
			password: ["", [Validators.minLength(6), Validators.required]],
			password_confirmation: ["", [Validators.minLength(6), Validators.required]]
		});
	}
	
	public handleUserFormData(data): void {
		let taxidData = JSON.parse(localStorage.getItem("formData"));
		let objToSent = JSON.parse(localStorage.getItem("formDataToSend"));
		objToSent["user_id"] = data.user_id;
		objToSent["user_service_id"] = (taxidData.path === 1) ? 3 : 4;
		objToSent['api_key'] = data.api_key;
		
		
		console.log(objToSent);
		this._RegistrationService.submitUserFormData(objToSent)
		.subscribe(res => {
			localStorage.removeItem("tempUserData");
			this.router.navigate(["/greetings"]);
		}, err => {
			console.log(err);
		});
	}
	
	public setupPassword(): void {
		let objToSent = JSON.parse(localStorage.getItem("tempUserData"));
		if (objToSent === null) {
			objToSent = {
				password: this.setupPwdForm.value.password
			};
		} else {
			objToSent["password"] = this.setupPwdForm.value.password;
		}
		
		this._RegistrationService.setupPassword(objToSent)
		.subscribe(res => {
			this.handleUserFormData({
				user_id: res.user_id,
				api_key: res.api_key
			});
		}, error => {
			console.log(error);
			this._alertService.error("Shared.Something_went_wrong");
		});
	}
	
}
