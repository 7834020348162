import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from "@angular/router";
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ModalModule, BsDatepickerModule, TooltipModule } from 'ngx-bootstrap';
import { InternationalPhoneNumberModule } from "ngx-international-phone-number-improved-final";
import { NgSelectModule } from '@ng-select/ng-select';

import { AlertService, SharedService, RegistrationService, EnvServiceProvider, FormService } from './_services/index';
import { JwtInterceptor, EncodeHttpParamsInterceptor } from './_helpers/index';

import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';

import { ErrorPageComponent } from './shared/components/error-page/error-page.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { AlertComponent } from './shared/components/alert/alert.component';

import { FormComponent } from './form/form.component';
import { RegistrationComponent } from './registration/registration/registration.component';
import { SmsConfirmationComponent } from './registration/sms-confirmation/sms-confirmation.component';
import { PwdSetupComponent } from './registration/pwd-setup/pwd-setup.component';

import { ConfirmEqualValidatorDirective, DateValidatorDirective, NumbersValidatorDirective, EmailValidatorDirective } from './_directives/index';
import { GreetingsComponent } from './greetings/greetings.component';
import { UserDataProtectionComponent } from './user-data-protection/user-data-protection.component';
import { OtherServicesFormComponent } from './other-services-form/other-services-form.component';

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    ErrorPageComponent,
    HeaderComponent,
    FooterComponent,
    AlertComponent,
    FormComponent,
    RegistrationComponent,
    SmsConfirmationComponent,
    PwdSetupComponent,
    ConfirmEqualValidatorDirective,
    DateValidatorDirective,
    GreetingsComponent,
    NumbersValidatorDirective,
    EmailValidatorDirective,
    UserDataProtectionComponent,
    OtherServicesFormComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    RouterModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    LoadingBarHttpClientModule,
    InternationalPhoneNumberModule,
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TooltipModule.forRoot()
  ],
  providers: [
    EnvServiceProvider,
    AlertService,
    SharedService,
    RegistrationService,
    FormService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EncodeHttpParamsInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
