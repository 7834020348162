import {Component, OnInit} from "@angular/core";
import {SharedService} from "../../../_services"

@Component({
	selector: "app-footer",
	templateUrl: "./footer.component.html",
	styleUrls: ["./footer.component.less"]
})
export class FooterComponent implements OnInit {

	lang = localStorage.getItem('lang');

	constructor(
		private _SharedService: SharedService
	) {
		/*if (this.lang === 'uk') {
			this.lang = 'uk'
		}*/
		this._SharedService.changeLanguageEvent
		.subscribe((res) => {
      this.lang = res;
			/*if (res === 'uk') {
				this.lang = 'ua'
			} else {
				this.lang = res;
			}*/
		});
	}

	ngOnInit() {
	}

}
