import {NgModule} from "@angular/core";
import {RouterModule, Routes, PreloadAllModules} from "@angular/router";

import {ErrorPageComponent} from "./shared/components/error-page/error-page.component";
import {FormComponent} from "./form/form.component";
import {RegistrationComponent} from "./registration/registration/registration.component";
import {SmsConfirmationComponent} from "./registration/sms-confirmation/sms-confirmation.component";
import {PwdSetupComponent} from "./registration/pwd-setup/pwd-setup.component";
import {GreetingsComponent} from "./greetings/greetings.component";
import {UserDataProtectionComponent} from "./user-data-protection/user-data-protection.component";
import {OtherServicesFormComponent} from "./other-services-form/other-services-form.component";

const routes: Routes = [
	{path: "", component: FormComponent},
	{path: "other-services", component: OtherServicesFormComponent},
	{path: "registration", component: RegistrationComponent},
	{path: "sms-confirmation", component: SmsConfirmationComponent},
	{path: "password-setup", component: PwdSetupComponent},
	{path: "greetings", component: GreetingsComponent},
	{path: "data-protection", component: UserDataProtectionComponent},
	{path: "**", component: ErrorPageComponent}
];

@NgModule({
	exports: [RouterModule],
	imports: [RouterModule.forRoot(routes, {
		preloadingStrategy: PreloadAllModules
	})],
	declarations: []
})
export class AppRoutingModule {
}
