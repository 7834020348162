import { Directive, Input } from '@angular/core';
import { Validator, NG_VALIDATORS, AbstractControl } from "@angular/forms";

@Directive({
  selector: '[appNumbersValidator]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: NumbersValidatorDirective,
    multi: true
  }]
})
export class NumbersValidatorDirective implements Validator {
  @Input() min: number;
  @Input() max: number;
  
  validate(control: AbstractControl): {[key: string]: any} | null {
    let isNumber = !isNaN(parseFloat(control.value)) && isFinite(control.value);
    if (!isNumber) {
      if (control.errors && !control.errors['required']) {
        return null;
      } else {
        return {'onlyNumberAvailable': true};
      }
    }
    /*if (!isNumber) {
      return {'onlyNumberAvailable': true};
    }*/
    if (this.min !== undefined) {
      if (control.value < this.min) {
        return { 'minValueError' : true };
      }
    }
    if (this.max !== undefined) {
      if (control.value > this.max) {
        return { 'maxValueError' : true };
      }
    }
    return null;
  }
  
  constructor() { }

}
