import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse, HttpParameterCodec, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { tap } from "rxjs/internal/operators";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  
  constructor() {}
  
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.api_key) {
      request = request.clone({
        setHeaders: {
          Authorization: currentUser.api_key
        }
      });
    }
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {}
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {}
      }, () => {
        console.log('complete');
      })
    );
  }
}

@Injectable()
export class EncodeHttpParamsInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const params = new HttpParams({encoder: new CustomEncoder(), fromString: req.params.toString()});
    return next.handle(req.clone({params}));
  }
}


class CustomEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }
  
  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }
  
  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }
  
  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}

